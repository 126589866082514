var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Masa")]),_c('div',{staticClass:"card-header-actions"},[_c('small',{domProps:{"textContent":_vm._s(_vm.modeTable === 'new' ? 'Yeni' : 'Düzenle')}})])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"10"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"value":_vm.formData.tableName,"label":"Masa Adı","placeholder":"Masa Adı"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "tableName", $event)}}})],1)],1)],1)],1),_c('hr'),_c('CRow',[_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[(_vm.modeTable !== 'new')?_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary"},on:{"click":function($event){return _vm.updateTable(_vm.id)}}},[_vm._v("Kaydet")]):_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary"},on:{"click":function($event){return _vm.createTable()}}},[_vm._v("Yeni Ekle")]),_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.cleanTable()}}},[_vm._v("Temizle")])],1)],1)],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Masalar")])]),_c('CCardBody',[_c('CDataTable',{attrs:{"columnFilter":true,"hover":true,"striped":true,"items":_vm.companyTables,"fields":[
              {
                key: 'id',
                label: 'ID',
              },
              {
                key: 'tableName',
                label: 'Masa Adı',
              },
              {
                key: 'qrcode',
                label: 'QR Kodu',
              },
              {
                key: 'actions',
                label: 'Aksiyonlar',
              } ],"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.editTable(item)}}},[_vm._v("Düzenle")]),_c('CButton',{attrs:{"size":"sm","variant":"outline","color":"danger"},on:{"click":function($event){return _vm.removeTable(item.id)}}},[_vm._v("Sil")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }